*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  scroll-behavior: smooth;
  /* font-family: 'Helvetica', sans-serif; */
}

p {
  font-family: 'Inter', sans-serif;
}

p > span {
  font-family: 'Inter', sans-serif;
}